<template>
  <div class="card-body pt-3 pb-0">
    <!--begin::Table-->

    <div
      class="alert alert-primary  mt-10 mb-5 p-5"
      role="alert"
      v-if="listComp.length == 0"
    >
      <h4 class="alert-heading">Sem consentimento!</h4>
      <p>Ainda não foi realizado consentimento nessa conta.</p>
      <div class="border-bottom border-white opacity-20 mb-5"></div>
      <p class="mb-0">
        Verifique se a nossa tag está instalada corretamente.
      </p>
    </div>

    <div class="table-responsive" v-else>
      <table class="table table-borderless table-vertical-center">
        <thead>
          <tr>
            <th class="p-0" style="min-width: 200px">
              {{ $t("WIDGETS.TABLE_CONSENTS.HEADER.UNIQUE_ID") }}
            </th>
            <th class="text-left" style="min-width: 200px">
              {{ $t("WIDGETS.TABLE_CONSENTS.HEADER.DATE") }}
            </th>
            <th class="text-left" style="min-width: 50px">
              {{ $t("WIDGETS.TABLE_CONSENTS.HEADER.CATEGORY") }}
            </th>
            <th class="text-left" style="min-width: 50px">
              {{ $t("WIDGETS.TABLE_CONSENTS.HEADER.CONSENSUS") }}
            </th>
            <th class="text-center pr-0" style="min-width: 50px">
              {{ $t("WIDGETS.TABLE_CONSENTS.HEADER.DETAILS") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in listComp">
            <tr v-bind:key="item._id">
              <td class="pl-0">
                <a
                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                >
                  {{ item._id }}
                </a>
                <div>
                  <a class="text-muted font-weight-bold text-hover-primary">
                    {{ item.createdAt }}
                  </a>
                </div>
              </td>

              <td class="text-left">
                <a class="text-muted font-weight-bold text-hover-primary">
                  {{ item.rawDate }}
                </a>
              </td>

              <td class="text-left">
                <span class="text-muted font-weight-500 text-capitalize">
                  {{ item.categories }}
                </span>
              </td>
              <td class="text-left">
                <span
                  class="label label-lg label-inline"
                  v-bind:class="`label-light-${item.style}`"
                >
                  {{ item.type }}
                </span>
              </td>
              <td class="text-center pr-0">
                <!-- <b-button v-b-modal.modal-1>Launch demo modal</b-button> -->
                <button
                  @click="showModal(item.consent)"
                  class="btn btn-icon btn-light btn-sm"
                >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="media/svg/icons/General/Settings-1.svg"
                    ></inline-svg>
                    <!--end::Svg Icon-->
                  </span>
                </button>
              </td>
            </tr>
          </template>
          <b-modal
            ref="consent-cookie"
            centered
            hide-footer
            id="consent-cookie"
            title="Detalhe de consentimento"
          >
            <!--begin::Wrapper-->
            <div class="d-flex justify-content-between flex-column h-100">
              <!--begin::Container-->
              <div class="">
                <!--begin::Header-->
                <div class="d-flex flex-column flex-center">
                  <!--end::Symbol-->
                  <!--begin::Username-->
                  <a
                    href="#"
                    class="card-title font-weight-bolder text-dark-75 text-hover-primary font-size-h4 m-0 pb-1"
                    >{{ consent._id }}</a
                  >
                  <!--end::Username-->
                  <!--begin::Info-->
                  <div class="font-weight-bold text-dark-50 font-size-sm pb-6">
                    {{ consent.rawDate }}
                    <!-- {{consent.createdAt}} -->
                  </div>
                  <!--end::Info-->
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="pt-1">
                  <!--begin::Text-->
                  <!--end::Text-->
                  <!--begin::Item-->
                  <div
                    class="d-flex align-items-center pb-9"
                    v-for="(category, index) in consent.consents"
                    :key="index"
                  >
                    <!--begin::Text-->
                    <div class="d-flex flex-column flex-grow-1">
                      <a
                        href="#"
                        class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder text-capitalize"
                        >{{ category._id.name }}</a
                      >
                      <span class="text-muted font-weight-bold">
                        <!-- <button
                            class="btn font-weight-bold btn-primary mr-5 mb-5"
                            v-for="cookie in category.cookies"
                            :key="cookie._id"
                          >
                            {{ cookie.name }}
                          </button> -->

                        <span
                          class="label label-primary  label-inline mr-2 mb-2"
                          v-for="cookie in category.cookies"
                          :key="cookie._id"
                          >{{ cookie.name }}</span
                        >
                      </span>
                    </div>
                    <!--end::Text-->
                    <!--begin::label-->
                    <span
                      class="font-weight-bolder label label-xl label-light-success label-inline px-3 py-5 min-w-45px"
                      >{{ category.cookies.length }}</span
                    >
                    <!--end::label-->
                  </div>
                  <!--end::Item-->
                </div>
                <!--end::Body-->
              </div>
              <!--eng::Container-->
            </div>
            <!--end::Wrapper-->
          </b-modal>
        </tbody>
      </table>
    </div>
    <!--end::Table-->
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TableConsents",

  props: {
    consentsList: { type: Array, required: true }
  },

  data() {
    return {
      consent: {},
      type: {
        all: {
          name: "Todos",
          style: "success"
        },
        custom: {
          name: "Personalizado",
          style: "warning"
        },
        optout: {
          name: "Optout",
          style: "danger"
        }
      }
    };
  },

  computed: {
    listComp() {
      let result = [];
      this.consentsList.forEach(item => {
        let categories = item.consents.map(category => {
          return category._id.name;
        });
        // moment.tz()
        let computed = {
          _id: item._id,
          createdAt: moment(item.createdAt, "", "America/Sao_Paulo")
            .locale("pt-br")
            .fromNow(),
          rawDate: moment(item.createdAt, "", "America/Sao_Paulo")
            .locale("pt-br")
            .format("DD/MM/YYYY, h:mm a"),
          categories: categories.sort().join(", "),
          type: this.type[item.type].name,
          style: this.type[item.type].style,
          consent: item
        };
        result.push(computed);
      });
      return result;
    }
  },

  methods: {
    parseDate(date) {
      return moment(date, "", "America/Sao_Paulo")
        .locale("pt-br")
        .fromNow();
    },
    showModal(consent) {
      this.consent = consent;
      this.consent.rawDate = moment(
        this.consent.createdAt,
        "",
        "America/Sao_Paulo"
      )
        .locale("pt-br")
        .format("DD/MM/YYYY, h:mm a");
      this.$refs["consent-cookie"].show();
    },
    hideModal() {
      this.$refs["consent-cookie"].hide();
    }
  }
};
</script>

<style scoped></style>
