<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Consentimentos
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          Últimos consentimentos realizados
        </span>
      </h3>

      <div class="row">
        <div class="col-md-8 my-2 my-md-0">
          <div class="input-icon">
            <input
              type="text"
              class="form-control"
              placeholder="Buscar..."
              v-model="qString"
            />
            <span>
              <i class="flaticon2-search-1 text-muted"></i>
            </span>
          </div>
        </div>
        <div class="col-md-4 my-2 my-md-0">
          <select v-model="qType" class="form-control">
            <option value="">Todos</option>
            <option value="all">Aceitou todos</option>
            <option value="custom">Parcial</option>
          </select>
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->

    <TableConsents :consentsList="consents.docs" />

    <pagination class="card-body py-0" :pagination="consents"></pagination>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import pagination from "@/view/content/widgets/pagination.vue";
// import modalAccount from "../modal/Index";
import TableConsents from "@/view/content/widgets/table-consents.vue";

export default {
  name: "consents",
  data() {
    return {
      show: "day",
      qString: "",
      qType: "",
      consents: {
        docs: [],
        hasNextPage: false,
        hasPrevPage: false,
        page: 1,
        limit: 10,
        nextPage: 2,
        pagingCounter: 1,
        prevPage: null,
        totalDocs: 29,
        totalPages: 3
      },
      consent: {},
      type: {
        all: {
          name: "Todos",
          style: "success"
        },
        custom: {
          name: "Personalizado",
          style: "warning"
        },
        optout: {
          name: "Optout",
          style: "danger"
        }
      }
    };
  },
  components: {
    pagination,
    TableConsents
  },
  mounted() {
    this.getConsents();

    this.$events.off("change-page");
    this.$events.on("change-page", page => {
      this.page = page;
      this.getConsents();
    });
  },
  methods: {
    getConsents() {
      ApiService.post(
        `visitorConsents/all/${this.$route.params.id}?page=${this.page}&limit=${this.limit}`,
        this.query
      )
        .then(({ data }) => {
          this.consents = data;
        })
        .catch(() => {
          //console.log("DATA ERROR", response);
        });
    }
  },
  computed: {
    page() {
      return this.consents.page || 1;
    },
    limit() {
      return this.consents.limit || 10;
    },
    query() {
      let params = {
        query: {}
      };
      if (this.qString != "") {
        params.query["_id"] = this.qString;
      }
      if (this.qType != "") {
        params.query["type"] = this.qType;
      }
      return params;
    }
  },
  watch: {
    page() {
      this.getConsents();
    },
    qString() {
      this.getConsents();
    },
    qType() {
      this.getConsents();
    }
  }
};
</script>
