<template>
  <div class="row">
    <div class="col-xl-12">
      <list></list>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import list from "./comps/list";

export default {
  name: "SiteConsents",

  props: {
    account: { type: Object, required: true }
  },

  components: {
    list
  },

  data() {
    return {
      selected: ""
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Site Consents" }]);
  }
};
</script>

<style></style>
